<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'turmas' })"
            :disabled="false"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Turma
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="turma">
        <v-card color="green lighten-1" class="mb-5" outlined>
          <v-card-title> Turma: {{ turma.descricao }}</v-card-title>
          <v-card-subtitle class="font-weight-medium">
            <div>Diários relacionados: {{ turma.gestao_de_aulas.length || "- - -" }} unidades</div>
            <div>Total de alunos: {{ matriculas.length || "- - -" }}</div>
            <div>Tipo de atendimento: {{ turma.tipo_de_atendimento || "- - -" }}</div>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-3">
      <template>
        <v-tabs v-model="tab_atual" background-color="primary" dark>
          <v-tab v-for="tab of tabs" :key="tabs.indexOf(tab)">{{ tab.descricao }}</v-tab>
        </v-tabs>
        <div class="d-flex text-right">
          <v-btn color="primary" class="ml-4 mt-4 mr-4 white--text" @click="gerarRelatorio">
            Lista da turma
            <v-icon right dark> mdi-file </v-icon>
          </v-btn>
          <v-btn
            v-if="!this.isHidden"
            color="primary"
            class="ml-4 mt-4 mr-4 white--text"
            @click="gerarBoletimGeral"
          >
            Boletim da turma
            <v-icon right dark> class </v-icon>
          </v-btn>
          <v-btn
            v-if="!this.isHidden"
            color="primary"
            class="ml-4 mt-4 mr-4 white--text"
            @click="resultadoPorDisciplina"
          >
            ATA Final
            <v-icon right dark> class </v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-simple-table v-if="matriculas" class="elevation-1" v-show="tab_atual == 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nº</th>
                  <th class="text-left">Aluno</th>
                  <th v-if="$can(['matriculas.rfid'])" class="text-left">RFID</th>
                  <th class="text-center">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(matricula, index) of matriculas"
                  :key="index"
                  :class="
                    $constants.situacoesMatriculaCondition[
                      removerEspaco(matricula.situacao ? matricula.situacao : '')
                    ]
                      ? 'red lighten-4'
                      : null
                  "
                >
                  <td width="20">{{ matriculas.indexOf(matricula) + 1 }}</td>
                  <td class="font-weight-medium text-left">
                    <v-row class="py-5">
                      <v-skeleton-loader v-if="!matricula.imagem" type="avatar"></v-skeleton-loader>
                      <v-avatar v-else size="48px">
                        <img alt="Avatar" :src="matricula.imagem" />
                      </v-avatar>
                      <div class="ml-2">
                        <span>{{ matricula.aluno.nomecompleto }}</span
                        ><br />
                        <small
                          >{{ matricula.aluno.codigo_inep || "- - -" }} /
                          {{ matricula.aluno.codigo || "- - -" }}</small
                        >
                      </div>
                    </v-row>
                  </td>
                  <td v-if="$can(['matriculas.rfid'])">
                    {{ matricula ? matricula.codigo_frid : "" }}
                  </td>
                  <td class="text-center">
                    <v-tooltip
                      bottom
                      v-if="
                        matricula.aluno &&
                        !isHidden &&
                        matricula.turma?.tipo_de_atendimento !==
                          'Atendimento educacional especializado (AEE)'
                      "
                    >
                      <v-tooltip bottom v-if="matricula.aluno && $can(['matriculas.rfid'])">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            x-small
                            v-if="
                              !$constants.situacoesMatriculaCondition[
                                removerEspaco(matricula.situacao ? matricula.situacao : '')
                              ]
                            "
                            class="ml-2"
                            color="red lighten-5"
                            v-bind="attrs"
                            v-on="on"
                            @click="() => formRfid(matricula, index)"
                          >
                            <v-icon color="red lighten-1" small> fa fa-id-card</v-icon>
                          </v-btn>
                        </template>
                        <span>Cadastrar código FRID</span>
                      </v-tooltip>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          v-if="
                            !$constants.situacoesMatriculaCondition[
                              removerEspaco(matricula.situacao ? matricula.situacao : '')
                            ]
                          "
                          class="ml-2"
                          color="red lighten-5"
                          v-bind="attrs"
                          v-on="on"
                          @click="() => boletimIndividual(matricula.id)"
                        >
                          <v-icon color="red lighten-1" small> fa fa-file-pdf</v-icon>
                        </v-btn>
                      </template>
                      <span>Boletim individual</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="matricula.aluno && isHidden">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-2"
                          color="red lighten-5"
                          v-bind="attrs"
                          v-on="on"
                          @click="() => RelatorioBNCC(matricula.id)"
                        >
                          <v-icon color="red lighten-1" small> fa fa-file-pdf</v-icon>
                        </v-btn>
                      </template>
                      <span>Gerar relatório infantil</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-simple-table v-if="gestoes_de_aulas" class="elevation-1" v-show="tab_atual == 1">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">N º</th>
                  <th class="text-left" width="300px">Disciplina</th>
                  <th class="text-left" width="300px">Professor</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="diario of gestoes_de_aulas" :key="gestoes_de_aulas.indexOf(diario)">
                  <td width="20">{{ gestoes_de_aulas.indexOf(diario) + 1 }}</td>
                  <td>{{ diario.disciplina.descricao }}</td>
                  <td>{{ diario.professor.nome }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </template>
    </v-card>
    <v-dialog :value="modal_rfid" persistent max-width="450px">
      <v-card>
        <v-card-title
          ><v-icon size="25" class="mr-2" color="primary"> credit_card </v-icon> Cadastrar RFID
          <v-spacer></v-spacer>
          <template>
            <v-btn @click="modal_rfid = !modal_rfid" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template></v-card-title
        >
        <v-card-text>
          <div class="d-flex align-cente mb-2">
            <span class="ml-4 text-body-2"></span>
          </div>
          <div class="d-flex flex-column">
            <v-form ref="form" @submit.prevent="cadastrar_rfid">
              <v-text-field
                v-model="form_rfid.rfid"
                filled
                prepend-inner-icon="mdi-account-outline"
                :label="`${aluno ? aluno.nomecompleto : ''}`"
                required
              />
            </v-form>
            <v-row class="mb-4">
              <v-container fluid>
                <v-btn @click="cadastrar_rfid" class="success_icon white--text" type="button">
                  Cadastrar código RFID
                </v-btn>
              </v-container>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      turma: null,
      matriculas: [],
      matriculasWithImg: [],
      gestoes_de_aulas: null,
      tab_atual: 0,
      tabs: [
        {
          id: 0,
          descricao: "Lista da Turma",
        },
        {
          id: 1,
          descricao: "Diários Relacionados",
        },
      ],
      isHidden: false,
      imagens: [],
      modal_rfid: false,
      form_rfid: {
        rfid: null,
      },
      aluno: null,
      matricula_index: null,
    };
  },
  mounted() {
    this.loadBasicData();
  },
  methods: {
    async gerarRelatorio() {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.listarTurma(parseInt(this.$route.params.turma_id, 10));
      this.$loaderService.close();
    },

    async resultadoPorDisciplina() {
      this.$loaderService.open("Carregando dados...");
      try {
        await this.$services.relatoriosService.resultadoPorDisciplina(
          parseInt(this.$route.params.turma_id, 10)
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async gerarBoletimGeral() {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.boletimGeral(
        parseInt(this.$route.params.turma_id, 10)
      );
      this.$loaderService.close();
    },

    async RelatorioBNCC(matricula_id) {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.relatorioBNCC(parseInt(matricula_id, 10));
      this.$loaderService.close();
    },

    async boletimIndividual(id) {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.boletimIndividual(parseInt(id, 10));
      this.$loaderService.close();
    },

    async loadBasicData() {
      this.$loaderService.open("Carregando dados...");
      const payload = await this.$services.turmasService.visualizarTurma(
        parseInt(this.$route.params.turma_id, 10)
      );
      this.turma = payload.turma;
      this.isHidden = payload.turma.sistema_bncc_id === 1;
      this.gestoes_de_aulas = payload.gestoes_de_aulas;

      this.matriculas = payload.matriculas;

      this.$loaderService.close();
      await payload.matriculas.forEach(async (matricula, index) => {
        const imagem = await this.verImage(matricula.aluno.id);
        matricula.imagem = imagem;
        this.$set(this.matriculas, index, matricula);
      });
      this.$forceUpdate();
    },
    removerEspaco(string) {
      return string.replace(" ", "_").replace(" ", "_").replace(" ", "_");
    },
    async verImage(aluno_id) {
      return this.$services.imageService.downloadImage(
        `notifiq/matriculas/download-image-aluno/${aluno_id}`
      );
    },
    async cadastrar_rfid() {
      try {
        const response = await this.$services.notifiqService.cadastrar_rfid(this.form_rfid);
        this.modal_rfid = !this.modal_rfid;

        this.$set(this.matriculas, this.matricula_index, {
          ...this.matriculas[this.matricula_index],
          codigo_frid: response.matricula.codigo_frid,
        });

        this.$toast.success("Código atualizado com sucesso.");
        // this.loadBasicData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    formRfid(matricula, matricula_index) {
      this.form_rfid.rfid = matricula.codigo_frid;
      this.form_rfid.matricula_id = matricula.id;
      this.aluno = matricula.aluno;
      this.matricula_index = matricula_index;
      this.modal_rfid = !this.modal_rfid;
    },
  },
};
</script>

<style></style>
