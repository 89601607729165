import Axios from "@/plugins/Axios";
import store from "@/store";
import Serie from "@/Models/Serie";

export class SeriesService {
  async syncAll() {
    const response = await Axios().get("series");
    const series = response.data.map((serie) => new Serie(serie));
    store.commit("Series/setSeries", series);
    return series;
  }

  async syncAllPaginated(pageNumber, query) {
    const response = await Axios().get(`series/series-pages?page=${pageNumber}&query=${query}`);
    return response.data;
  }

  async criarSerie(serie) {
    const response = await Axios().post("series", serie);
    const novaSerie = response.data;
    store.commit("Series/adicionarSerie", novaSerie);
    return novaSerie;
  }

  async atualizarSerie(serie) {
    const response = await Axios().put(`series/${serie.id}`, serie);
    const serieAtualizada = response.data;
    store.commit("Series/atualizarSerie", serieAtualizada);
    return serieAtualizada;
  }

  async deletarSerie(serie) {
    const response = await Axios().delete(`series/${serie.id}`);
    const serieDeletada = response.data;
    store.commit("Series/deletarSerie", serie.id);
    return serieDeletada;
  }

  async recuperarSerie(serie) {
    return this.atualizarSerie({ ...serie, deleted_at: null });
  }
}
export default new SeriesService();
