<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-chip :color="qtdIguais ? 'green' : 'red'" label small v-bind="attrs" v-on="on">
        {{ qtdAulasAprovadas }}/{{ qtdAulas }}
      </v-chip>
    </template>
    <span>
      <div :key="situacao" v-for="[situacao, quantidade] of Object.entries(resumoAulas)">
        {{ situacao }}: {{ quantidade }}
      </div>
    </span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    aulas: {
      type: Array,
      required: true,
    },
  },
  computed: {
    qtdAulasAprovadas() {
      return this.aulas.filter((aula) => aula.situacao === "Aula confirmada").length;
    },
    qtdAulas() {
      return this.aulas.length;
    },
    qtdIguais() {
      return this.qtdAulasAprovadas === this.qtdAulas;
    },
    resumoAulas() {
      const resumo = this.aulas.reduce((accumulator, aula) => {
        const situacao = aula.situacao || "Aguardando confirmação";
        accumulator[situacao] = Number.isInteger(accumulator[situacao])
          ? accumulator[situacao] + 1
          : 1;
        return accumulator;
      }, {});
      return resumo;
    },
  },
};
</script>

<style></style>
