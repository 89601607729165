<template>
  <v-menu :close-on-content-click="false" :nudge-width="100" offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        style="box-shadow: none"
        class="text-none"
        v-if="autorizacoesDados.length > 0"
        :content="autorizacoesDados.length"
        color="error"
      >
        <v-badge
          v-if="autorizacoesDados.length > 0"
          :content="autorizacoesDados.length"
          color="error"
        >
          <v-icon> fa fa-unlock-alt </v-icon>
        </v-badge>
      </v-btn>
      <v-btn v-else icon v-bind="attrs" v-on="on" style="box-shadow: none" class="text-none">
        <v-icon aria-hidden="true">fa fa-bell</v-icon>
      </v-btn>
    </template>

    <v-card
      @click="pushAutorizacoes()"
      v-if="autorizacoesDados.length > 0"
      class="mx-auto"
      max-width="430"
    >
      <v-list>
        <v-list-item v-for="(autorizacao, i) of autorizacoesDados" :key="i">
          <v-list-item-avatar color="green">
            <v-list-item-title style="color: #fff"></v-list-item-title>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title> Pedido de Autorização</v-list-item-title>
            <v-list-item-subtitle>Tipo: {{ autorizacao?.pedido?.descricao }}</v-list-item-subtitle>
            <v-list-item-subtitle
              >Solicitado por: {{ autorizacao.solicitante.name }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              >Direcionado para: {{ autorizacao.aprovador.name }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ENotificacoesList",
  computed: {
    ...mapGetters("Autorizacoes", ["autorizacoes", "autorizacoesList"]),
  },
  data() {
    return {
      qtdPedidosPendentes: 0,
      autorizacoesDados: [],
    };
  },
  mounted() {
    this.loadPedidos();
    this.autorizacoesDados = this.autorizacoesList.filter((a) => a.status === "PENDENTE");
  },
  methods: {
    ...mapActions("Autorizacoes", ["loadAutorizacoes"]),

    async loadPedidos() {
      try {
        this.$loaderService.open("Carregando dados");
        await Promise.all([this.loadAutorizacoes()]);
        this.$loaderService.close();
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
    pushAutorizacoes() {
      this.$router.push({ name: "autorizacoes" });
    },
  },
};
</script>
