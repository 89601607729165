<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'usuarios' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Lista de Usuários COM/SEM bloqueios
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-sheet color="white" elevation="1" rounded>
          <v-lazy>
            <div class="text-start py-2 px-4 text-button">Seleção avançada</div>
          </v-lazy>
          <v-divider class="theme--light"></v-divider>
          <div class="py-2 px-3">
            <v-row
              style="cursor: pointer"
              @click="expand_bloqueio_geral = !expand_bloqueio_geral"
              class="py-2 cursor"
              justify="center"
            >
              <div class="text-start px-4 text-button">Bloqueio geral</div>
            </v-row>
            <ValidationObserver v-slot="{ handleSubmit }">
              <v-form @submit.prevent.stop="handleSubmit(submitForm)" :disabled="submittingForm">
                <v-expand-transition>
                  <div v-show="expand_bloqueio_geral">
                    <v-row>
                      <v-col cols="12" sm="7" xs="12">
                        <e-label>Escolha o(s) grupo</e-label>
                        <ValidationProvider
                          name="regras/grupos"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <e-autocomplete
                            :items="$constants.rolesGeral"
                            :return-object="false"
                            :error-messages="errors"
                            v-model="form.roles"
                            label="Selecione uma opção"
                            item-text="name"
                            item-value="id"
                            dense
                            solo
                            multiple
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="5" xs="12">
                        <e-label>Escolha o(s) situaçao</e-label>
                        <e-autocomplete
                          :items="$constants.situacoesUsuarios"
                          :return-object="false"
                          v-model="form.situacao"
                          item-text="descricao"
                          item-value="descricao"
                          label="Selecione uma opção"
                          dense
                          solo
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0 pb-5">
                        <v-btn
                          color="primary"
                          :disabled="submittingForm"
                          :loading="submittingForm"
                          type="submit"
                          block
                          >Enviar Dados</v-btn
                        >
                      </v-col>
                    </v-row>
                  </div>
                </v-expand-transition>
              </v-form>
            </ValidationObserver>
          </div>
          <v-divider></v-divider>
          <div class="py-2 px-3">
            <v-row
              style="cursor: pointer"
              @click="expand_bloqueio_aulas_bime = !expand_bloqueio_aulas_bime"
              class="py-2 cursor"
              justify="center"
            >
              <div class="text-start px-4 text-button">Bloqueio de aulas e bimestres</div>
            </v-row>
            <v-expand-transition>
              <div class="px-5" v-show="expand_bloqueio_aulas_bime">
                <v-row class="mt-4">
                  <v-col cols="5">
                    <e-label>Escolha o(s) grupo</e-label>
                    <ValidationProvider name="regras/grupos" rules="required" v-slot="{ errors }">
                      <e-autocomplete
                        :items="$constants.rolesGeral"
                        :return-object="false"
                        :error-messages="errors"
                        v-model="form.grupos_bloqueados"
                        label="Selecione uma opção"
                        item-text="name"
                        item-value="id"
                        dense
                        solo
                        multiple
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="4">
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="data_bloqueio"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <e-label>Bloqueio de lançamento de aulas</e-label>
                        <v-text-field
                          v-model="form.data_bloqueio"
                          persistent-hint
                          label="Escolha uma data"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          solo
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="data_bloqueio" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                        <v-btn text color="primary" @click="$refs.dialog.save(data_bloqueio)">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col class="pt-10 pb-0" cols="3">
                    <v-btn color="success" @click="data_bloqueio = ''">Limpar data</v-btn>
                  </v-col>
                </v-row>
                <div class="mb-5">
                  <v-divider></v-divider>
                </div>

                <!-- Bloqueio bimestral -->
                <v-row>
                  <v-col class="pt-0 pb-0" cols="6">
                    <e-label>Bloqueio de lançamento de planos</e-label>
                    <v-select
                      :items="$constants.bimestresBloqueio"
                      :return-object="false"
                      v-model="form.bimestres"
                      label="Selecione uma opção"
                      item-text="desc"
                      item-value="value"
                      dense
                      solo
                      multiple
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0 pb-5">
                    <v-btn color="primary" @click="() => submitFormAulas()" type="submit" block
                      >Enviar Dados</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
          </div>

          <div>
            <v-divider></v-divider>
          </div>
          <v-col>
            <v-subheader
              >* Todos os membros pertencentes ao grupo sofrerá o mesmo impacto. Por isso preste
              atenção antes de executar a ação.</v-subheader
            >
            <v-subheader
              >* Para bloquear o lançamento de aulas e planos a SITUAÇÃO não precisa está como
              bloqueada, basta informar a data e o grupo.</v-subheader
            >
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="search"
          class="search-input"
          append-icon="mdi-magnify"
          label="Pesquisar..."
          solo
          dense
          single-line
          hide-details
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <template>
          <v-data-table
            v-model="selected"
            :headers="table.headers"
            :items="outrosUsuariosList"
            :single-select="singleSelect"
            hide-default-footer
            item-key="name"
            class="elevation-1"
          >
            <template v-slot:item.role_id="{ item }">
              <e-user-role :value="item" />
            </template>
            <template v-slot:item.situacao="{ item }">
              <e-user-situacao :value="item" />
            </template>
            <template v-slot:item.data_bloqueio="{ item }">
              <e-user-data-bloqueio :value="item" />
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip v-if="!item.estaDeletado" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    class="ml-1"
                    color="blue-grey lighten-3"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => editarUsuario(item)"
                  >
                    <v-icon small>fa fa-edit </v-icon>
                  </v-btn>
                </template>
                <span>Editar usuario</span>
              </v-tooltip>
              <v-tooltip v-if="!item.estaDeletado" color="error" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    class="ml-1"
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => deletarUsuario(item)"
                  >
                    <v-icon small>fa fa-trash </v-icon>
                  </v-btn>
                </template>
                <span>Apagar usuario</span>
              </v-tooltip>
              <v-tooltip v-if="item.estaDeletado" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    class="ml-1"
                    color="info"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => recuperarUsuario(item)"
                  >
                    <v-icon small>fa fa-sync </v-icon>
                  </v-btn>
                </template>
                <span>Recuperar usuario</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <e-paginate
            :current_page="paginate.current_page"
            :last_page="paginate.last_page"
            :pageNumber="pageNumber"
            @changeActionResponse="carregarUsuarios"
            :search="search"
          ></e-paginate>
        </template>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";
import Usuario from "../../Models/User";
import { $constants } from "../../plugins/constants";

export default {
  props: {},
  mounted() {
    this.carregandoUsuarios = true;
    this.$loaderService.open("Carregando Usuarios");
    this.carregarUsuarios(this.pageNumber);
    this.carregandoUsuarios = false;
    this.$loaderService.close();
    this.getRoles();
  },
  data() {
    return {
      submittingForm: false,
      expand_bloqueio_geral: false,
      expand_bloqueio_aulas_bime: false,
      form: {
        situacao: null,
        grupos_bloqueados: [],
        data_bloqueio: null,
        bimestres: [],
      },
      roles: [],
      selected: [],
      singleSelect: false,
      usuarios: [],
      carregandoUsuarios: false,
      usuariosLoaded: false,
      usuariosList: [],
      outrosUsuariosList: [],
      dataTableFilter,
      search: "",
      pageNumber: 0,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Nome", value: "name" },
          { text: "Login de acesso", value: "email" },
          { text: "Perfil", value: "role_id" },
          { text: "Situação", value: "situacao" },
          { text: "Data bloqueio", value: "data_bloqueio" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
      check: false,
      data_bloqueio: null,
      modal: false,
      grupos_bloqueados: [],
    };
  },
  watch: {
    carregandoUsuarios(value) {
      if (value && this.outrosUsuariosList.length === 0) {
        this.$loaderService.open("Carregando usuarios");
      } else {
        this.$loaderService.close();
      }
    },
    async search(val) {
      if (val.length > 1) {
        await this.carregarUsuarios(0, val);
      }
      if (!val) {
        await this.carregarUsuarios(0);
      }
    },
    data_bloqueio(value) {
      if (!value) {
        this.form.data_bloqueio = value;
        return;
      }
      const data_old = new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(
        new Date(value)
      );
      this.form.data_bloqueio = data_old.replaceAll("/", "/");
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    async submitForm() {
      this.$toast.warn("Tentando atualizar os dados, aguarde!");
      this.submittingForm = true;
      try {
        await this.$services.rolesService.allUsers(this.form);
        this.$toast.success("Usuario(s) atualizado(s) com sucesso");
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.submittingForm = false;
      }
    },
    async submitFormAulas() {
      this.$toast.warn("Tentando atualizar os dados, aguarde!");
      this.submittingForm = true;
      if (this.form.data_bloqueio) {
        this.form.data_bloqueio = this.form.data_bloqueio.split("/").reverse().join("-");
      }
      try {
        await this.$services.rolesService.allUsersData(this.form);
        this.$toast.success("Usuario(s) atualizado(s) com sucesso");
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.submittingForm = false;
      }
    },
    async getRoles() {
      try {
        const response = await this.$services.rolesService.syncAll();
        this.roles = response.roles;

        if (response.data) {
          const dataBloqueio = response.data.data_bloqueio;
          if (dataBloqueio) {
            this.data_bloqueio = dataBloqueio.data_bloqueio.data_bloqueio;
            this.form.grupos_bloqueados = dataBloqueio.roles;
            this.form.bimestres = dataBloqueio.bimestres;
          }
        }

        return;
      } catch (error) {
        this.$handleError(error);
      }
    },
    addSelect(usuario) {
      if (!this.usuarioInseridoNoSelected(usuario)) {
        this.selected.push(usuario);
      }
    },
    editarUsuario(usuario) {
      this.$router.push({ name: "usuarios.edit", params: { usuario_id: usuario.id } });
    },
    async deletarUsuario(usuario) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta usuario?",
        confirmationCode: usuario.id,
        confirmationMessage: `Por favor, digite <strong>${usuario.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Usuario");
              try {
                await this.$services.usuariosService.deletarUsuario(usuario);
                this.$toast.success("Usuario deletada com sucesso");
                await this.carregarUsuarios();
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
    },
    recuperarUsuario(usuario) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta usuario?",
        confirmationCode: usuario.id,
        confirmationMessage: `Por favor, digite <strong>${usuario.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Usuario");
              try {
                await this.$services.usuariosService.recuperarUsuario(usuario);
                this.$toast.success("Usuario recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
    },
    async carregarUsuarios(pageNumber, query = null) {
      try {
        const response = await this.$services.usuariosService.syncAllPaginate(pageNumber, query);
        this.paginate = response.data;
        const usuarios = response.data.data.map((usuario) => new Usuario(usuario));
        this.outrosUsuariosList = usuarios;
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style>
.cursor:hover {
  background: #faf7f7 !important;
}
</style>
