<template>
  <main-template hide-franquia>
    <v-row class="mx-3">
      <v-col cols="12" class="mx-1">
        <v-img src="/img/portal_aluno.svg" style="max-width: 100%; height: auto" />
      </v-col>

      <v-col cols="12" class="mx-1">
        <small>Bem vindo(a)!</small>
        <v-row class="mt-2">
          <v-skeleton-loader class="mb-4" v-if="!aluno_imagem" type="avatar"></v-skeleton-loader>
          <v-avatar class="mb-4" v-else size="65px">
            <img alt="Avatar" :src="aluno_imagem" />
          </v-avatar>
          <h1 class="ml-3 mt-4">{{ this.nomeAluno }}</h1>
        </v-row>
      </v-col>

      <v-col>
        <v-alert dismissible v-if="message" outlined type="error" prominent border="left">
          {{ message }}
        </v-alert>
        <template v-if="avisos.length > 0">
          <div>
            <v-alert color="error" icon="warning" border="left" elevation="2" colored-border>
              <v-row align="center">
                <v-col class="grow"><strong>Avisos</strong> </v-col>
                <v-btn small :to="{ name: 'avisos.portal' }" color="secondary"> Ver todos </v-btn>
              </v-row>
              <div v-for="(aviso, index) in avisos" :key="index" class="mt-1">
                <v-col class="grow">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-row class="px-3" justify="space-between">
                        <span class="d-inline-block title-1 font-weight-bold text-truncate box">
                          {{ aviso.titulo ? aviso.titulo : "" }}
                        </span>
                        <v-btn icon @click="() => verAviso(aviso.id, aviso)" color="secondary">
                          <span class="material-icons">
                            <span class="material-icons"> visibility </span>
                          </span>
                        </v-btn>
                      </v-row>
                      <!-- Block level -->
                      <div>
                        <span class="d-inline-block subtitle-1 font-weight-light text-truncate box">
                          {{ aviso.contexto ? aviso.contexto : "" }}
                        </span>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </v-col>
              </div>
            </v-alert>
          </div>
        </template>
        <template>
          <div>
            <v-alert color="secondary" border="left" elevation="2" colored-border icon="mdi-book">
              <v-row align="center">
                <v-col class="grow"> Suas <strong>agendas</strong> da semana! </v-col>
                <v-btn small :to="{ name: 'agenda.aluno' }" color="secondary"> Ver todos </v-btn>
              </v-row>
              <div class="mt-1" v-for="(item, index) in agendas_semana" :key="index">
                <v-row align="center">
                  <v-col class="grow"> {{ item.dia ? item.dia : "" }} </v-col>
                  <v-col class="shrink">
                    <v-btn icon @click="() => expandirAgenda(item, index)" color="secondary">
                      <span class="material-icons">
                        {{ item.expand ? "expand_less" : "expand_more" }}
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider class="mt-1"></v-divider>
                <v-expand-transition>
                  <v-row class="mt-1" v-show="item.expand || false">
                    <v-list-item v-for="(item_agenda, i) in item.agenda" :key="i" two-line>
                      <v-list-item-content>
                        <v-list-item-title class="overline">{{
                          item_agenda.titulo ? item_agenda.titulo : ""
                        }}</v-list-item-title>
                        <v-list-item-subtitle class="caption"
                          ><v-chip label color="warning" small>
                            {{ item_agenda.user ? item_agenda.user.name : "" }}</v-chip
                          >
                        </v-list-item-subtitle>
                        <div class="caption font-weight-hint">
                          {{ item_agenda.conteudo ? item_agenda.conteudo : "" }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                </v-expand-transition>
              </div>
            </v-alert>
          </div>
        </template>
        <h2>Minhas Matrículas</h2>
        <v-simple-table class="mt-3">
          <template>
            <thead>
              <tr>
                <th class="text-left">Matricula</th>
                <th class="text-left">Ano</th>
                <th class="text-left">Escola</th>
                <th class="text-left">Série</th>
                <th class="text-left">Turma</th>
                <th class="text-left">Visualizar</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="matricula in historicoAluno" :key="matricula.id">
                <td>{{ matricula.id }}</td>
                <td>{{ matricula.ano ? matricula.ano.descricao : "- - -" }}</td>
                <td>{{ matricula.turma ? matricula.turma.franquia.descricao : "- - -" }}</td>
                <td>{{ matricula.serie ? matricula.serie.descricao : "- - -" }}</td>
                <td>
                  <v-chip class="ma-2" color="green" text-color="white">
                    {{ matricula.turma ? matricula.turma.descricao : "- - -" }}
                  </v-chip>
                </td>

                <td width="20%" v-if="matricula.turma">
                  <v-tooltip color="blue-grey lighten-3" bottom offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="!matricula.turma.isInfantil"
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        @click="boletim(matricula.id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small class="ml-1">fa fa-file </v-icon>
                      </v-btn>
                    </template>
                    <span>Boletim</span>
                  </v-tooltip>
                  <v-tooltip color="blue-grey lighten-3" bottom offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        v-if="matricula.turma.isInfantil"
                        color="blue-grey lighten-3"
                        @click="relatorioBncc(matricula.id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small class="ml-1">fa fa-file </v-icon>
                      </v-btn>
                    </template>
                    <span>Relatório BNCC</span>
                  </v-tooltip>
                  <v-tooltip color="blue-grey lighten-3" bottom offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="openModalSelectMonths(matricula.id)"
                      >
                        <v-icon small class="ml-1">fa fa-address-book </v-icon>
                      </v-btn>
                    </template>
                    <span>Frequência</span>
                  </v-tooltip>
                  <v-tooltip color="blue-grey lighten-3" bottom offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="!matricula.turma.isInfantil"
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        @click="desempenhoDoAluno(matricula.id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small class="ml-1">fa fa-bar-chart</v-icon>
                      </v-btn>
                    </template>
                    <span>Desempenho</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <e-select-months-modal
      @dialogChange="dialog = $event"
      :dialog="dialog"
      confirmText="Gerar frequência"
      cancelText="Fechar"
      @changeAction="frenquenciaIndividual"
    ></e-select-months-modal>
    <div class="text-center">
      <v-dialog v-model="dialog_aviso" width="374">
        <v-card v-if="!aviso" class="mx-auto" max-width="374">
          <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader class="mx-auto" max-width="374" type="card"></v-skeleton-loader>
          </v-sheet>
        </v-card>

        <v-card v-else class="mx-auto" max-width="374">
          <v-img :src="url_image_aviso" height="200px"></v-img>

          <v-card-title> {{ aviso ? aviso.titulo : "- - -" }} </v-card-title>

          <v-card-subtitle> {{ aviso ? aviso.contexto : "- - -" }} </v-card-subtitle>

          <v-card-actions>
            <v-btn @click="dialog_aviso = false" color="orange lighten-2" text> Fechar </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  data() {
    return {
      nomeAluno: "",
      historicoAluno: [],
      message: null,
      dialog: false,
      dialog_aviso: false,
      matricula_selected: null,
      expand_agenda: false,
      input: "",
      agendas_semana: [],
      matricula_atual: null,
      tab: null,
      agenda: [],
      aluno_imagem: null,
      avisos: [],
      aviso: null,
      url_image_aviso: null,
    };
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  async mounted() {
    // this.$Tawk.$setOnLoadFunction(() => this.$Tawk.$hideWidget());
    this.$loaderService.open("Carregando dados...");
    try {
      await this.matriculas();
      await this.pegarNomeAluno();
      await this.verImage();
    } catch (error) {
      this.$handleError(error);
    } finally {
      this.$loaderService.close();
    }
  },
  methods: {
    expandirAgenda(agenda, index) {
      agenda.expand = !agenda.expand;
      this.$set(this.agendas_semana, index, agenda);
    },
    openModalSelectMonths(matricula_id) {
      this.matricula_selected = matricula_id;
      this.dialog = !this.dialog;
    },
    async pegarNomeAluno() {
      this.nomeAluno = this.user.name.toLowerCase();
      const nome = this.nomeAluno.charAt(0).toUpperCase();
      this.nomeAluno = nome + this.nomeAluno.slice(1);
    },
    async matriculas() {
      const { aluno_id } = this.user;
      const response = await this.$services.matriculasService.historico(aluno_id);
      this.historicoAluno = response.data.alunos;
      await this.agendasDaSemana(response.data.matricula_ano_atual.turma_id);
      await this.avisosLimit(response.data.matricula_ano_atual.turma_id);
    },
    async boletim(matricula_id) {
      this.$loaderService.open("Carregando dados...");
      try {
        await this.$services.responsavelAlunoService.boletim(parseInt(matricula_id, 10));
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async agendasDaSemana(turma_id) {
      try {
        const response = await this.$services.agendasService.agendasDaSemana(turma_id);
        this.agendas_semana = response;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async avisosLimit(turma_id) {
      try {
        const response = await this.$services.avisosService.avisosLimit(turma_id);
        this.avisos = response.avisos;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async frenquenciaIndividual(mes) {
      this.$loaderService.open("Carregando dados...");
      try {
        await this.$services.relatoriosService.frenquenciaIndividual(
          parseInt(this.matricula_selected, 10),
          parseInt(mes.id, 10)
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async relatorioBncc(matricula_id) {
      try {
        this.$loaderService.open("Carregando dados");
        await this.$services.responsavelAlunoService.relatorioBncc(parseInt(matricula_id, 10));
        this.$loaderService.close();
      } catch (error) {
        this.$loaderService.close();
        this.message = "A turma não esta vinculado a um curso, entre em contato com a direção!";
      }
    },
    async desempenhoDoAluno(matricula_id) {
      this.$loaderService.open("Carregando dados...");
      try {
        this.$router.push({
          name: "matriculas.desempenhoNota",
          params: { matricula_id },
        });
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async verImage() {
      this.aluno_imagem = await this.$services.imageService.downloadImage(
        `notifiq/matriculas/download-image-aluno/${this.user.aluno_id}`
      );
    },
    async verAviso(aviso_id, aviso) {
      this.dialog_aviso = !this.dialog_aviso;
      this.$forceUpdate();
      this.url_image_aviso = await this.$services.imageService.downloadImage(
        `notifiq/avisos/download-image/${aviso_id}`
      );
      this.aviso = aviso;
    },
  },
};
</script>
<style scoped>
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .box {
    width: 650px;
  }
}
@media only screen and (max-width: 480px) {
  /* For desktop: */
  .box {
    width: 200px;
  }
}
</style>
