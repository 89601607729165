<template>
  <div class="d-flex align-center">
    <template
      v-if="
        $constants.situacoesMatriculaCondition[
          removerEspaco(matricula.situacao ? matricula.situacao : '')
        ]
      "
    >
      <v-currency-field
        locale="en-US"
        :default-value="null"
        :value="value || null"
        hide-details
        :allow-negative="false"
        dense
        :min="min"
        :decimal-length="1"
        :max="max"
        ref="input"
        v-bind="{ outlined: true }"
        v-bind:style="{
          'min-width': '60px !important',
          'max-width': '60px !important',
        }"
        :disabled="true"
      />
    </template>
    <template v-else-if="tipoValor === 'valor_texto'">
      <v-select
        v-if="etapaAtual == 10 && matricula.situacao == 'TRANSFERIDO'"
        @change="verificarVal($event)"
        :items="optionsNotaTextoFinal"
        :item-value="(value) => value.descricao"
        :item-text="(value) => value.descricao"
        :value="'TRANSFERIDO'"
        label="TRANSFERIDO"
        disabled
        outlined
      ></v-select>
      <v-select
        v-if="etapaAtual == 10 && notaTexto && matricula.situacao !== 'TRANSFERIDO'"
        @change="verificarVal($event)"
        :items="optionsNotaTextoFinal"
        :item-value="(value) => value.descricao"
        :item-text="(value) => value.descricao"
        :value="notaTexto.valor_texto"
        label="Selecione um resultado."
        outlined
      ></v-select>
      <v-select
        v-else-if="etapaAtual == 10 && valorSelect && matricula.situacao !== 'TRANSFERIDO'"
        @change="verificarVal($event)"
        :items="optionsNotaTextoFinal"
        :item-value="(value) => value.descricao"
        :item-text="(value) => value.descricao"
        :value="valorSelect"
        label="Selecione um resultado.."
        outlined
      ></v-select>
      <v-select
        v-else-if="notaTexto && matricula.situacao == 'TRANSFERIDO' && etapaAtual != 10"
        @change="verificarVal($event)"
        :items="optionsNotaTexto"
        :item-value="(value) => value.descricao"
        :item-text="(value) => value.descricao"
        :value="notaTexto.valor_texto"
        label="Selecione um tipo"
        disabled
        outlined
      ></v-select>
      <v-select
        v-else-if="notaTexto && matricula.situacao !== 'TRANSFERIDO'"
        @change="verificarVal($event)"
        :items="optionsNotaTexto"
        :item-value="(value) => value.descricao"
        :item-text="(value) => value.descricao"
        :value="notaTexto.valor_texto"
        label="Selecione um tipo."
        outlined
      ></v-select>
      <v-select
        v-else-if="valorSelect && matricula.situacao !== 'TRANSFERIDO'"
        @change="verificarVal($event)"
        :items="optionsNotaTexto"
        :item-value="(value) => value.descricao"
        :item-text="(value) => value.descricao"
        :value="valorSelect"
        label="Selecione um tipo.."
        outlined
      ></v-select>

      <v-select
        v-else
        v-show="matricula.situacao !== 'TRANSFERIDO'"
        @change="verificarVal($event)"
        :items="optionsNotaTexto"
        :item-value="(value) => value.descricao"
        :item-text="(value) => value.descricao"
        label="Selecione um tipo"
        outlined
      ></v-select>
    </template>
    <template v-else>
      <v-currency-field
        locale="en-US"
        :default-value="null"
        @input="(val) => handleInput(val)"
        :value="value || null"
        hide-details
        :allow-negative="false"
        dense
        :min="min"
        :max="max"
        :decimal-length="1"
        ref="input"
        v-bind="{ outlined: true }"
        v-bind:style="{ 'min-width': '60px !important', 'max-width': '60px !important' }"
        :disabled="disabled"
      />
      <!-- :maxlength="2" -->
      <v-btn
        icon
        small
        v-show="value === null && value !== undefined && !disabled"
        color="error"
        class="red lighten-5 ml-2"
        @click="emitNull"
      >
        <v-icon x-small>fa fa-close</v-icon>
      </v-btn>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
    },
    tipoValor: {
      type: [String],
    },
    circuitoId: {
      type: Number,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: 100,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    matricula: {
      type: Object,
      required: true,
    },
    notaTexto: {
      type: Object,
      required: false,
    },
    notaTextoLetra: {
      type: String,
    },
    etapaAtual: {
      type: Number,
    },
  },
  watch: {
    etapaAtual(value) {
      console.log(value);
      this.$loaderService.open("Carregando opções de notas...");
      this.getOptionsSelect(value);
      if (this.notaTextoLetra) {
        this.valorSelect = this.notaTextoLetra;
      } else {
        this.valorSelect = value;
      }
    },
  },
  data() {
    return {
      optionsNotaTexto: [],
      optionsNotaTextoFinal: [],
      valorSelect: null,
    };
  },
  mounted() {
    this.$loaderService.open("Carregando notas...");
    this.getOptionsSelect(this.etapaAtual);
  },
  methods: {
    verificarVal($event) {
      this.$emit("add", $event);
    },
    async getOptionsSelect(etapa) {
      const circuito = await this.$services.circuitoNotasService.getCircuito(this.circuitoId);
      const constExiste = circuito.valores_notas_texto.filter(
        (item) => item.etapa_final === etapa
      ).length;
      this.optionsNotaTexto = circuito.valores_notas_texto.filter((item) => item.etapa_final === 0);

      const newObject = { id: 0, descricao: "- - -", descricao_texto: null };

      const newArray = [...this.optionsNotaTexto, newObject];
      this.optionsNotaTexto = newArray;
      if (constExiste > 0) {
        this.optionsNotaTextoFinal = circuito.valores_notas_texto.filter(
          (item) => item.etapa_final === etapa
        );
      }
      this.$loaderService.close();
    },
    handleInput(val) {
      const notasSplit = val.toString();
      const arr = notasSplit.split(".");

      if (arr[0] !== "0") {
        if (arr[1] === "0" || arr[1] === "1" || arr[1] === "2") {
          val = `${arr[0]}.${0}`;
        } else if (
          arr[1] === "3" ||
          arr[1] === "4" ||
          arr[1] === "5" ||
          arr[1] === "6" ||
          arr[1] === "7"
        ) {
          val = `${arr[0]}.${5}`;
        } else if (arr[1] === "8" || arr[1] === "9") {
          const result = parseInt(arr[0], 10) + 1;
          val = `${result}.0`;
        }
      }

      if (val > this.max) {
        this.$emit("change", parseFloat(val, 10));
        this.$refs.input.$emit("input", this.max);
        this.$emit("input", parseFloat(val, 10));
      } else if (val < this.min) {
        this.$refs.input.$emit("input", this.min);
      } else {
        this.$emit("input", parseFloat(val, 2));
        this.$emit("change", parseFloat(val, 2));
      }

      // if (val > this.max) {
      //   this.$refs.input.$emit("input", this.max);
      // } else if (val < this.min) {
      //   this.$refs.input.$emit("input", this.min);
      // } else {
      //   this.$emit("input", parseFloat(val, 10));
      //   this.$emit("change", parseFloat(val, 10));
      // }
    },
    emitNull() {
      // this.$emit("input", undefined);
      // this.$emit("change", undefined);
      setTimeout(() => {
        // eslint-disable-next-line
        this.$refs.input._data.formattedValue = "";
      }, 150);
    },
    removerEspaco(string) {
      // return string.replace(" ", "_").replace(" ", "_").replace(" ", "_");
    },
  },
};
</script>
