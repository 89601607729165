<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'disciplinas' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Disciplina
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="4">
            <ValidationProvider name="Código" rules="required" v-slot="{ errors }">
              <e-label>Código</e-label>
              <v-text-field :error-messages="errors" v-model="form.codigo" dense solo />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="8">
            <ValidationProvider name="Descrição" rules="required" v-slot="{ errors }">
              <e-label>Descrição</e-label>
              <v-text-field :error-messages="errors" v-model="form.descricao" dense solo />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="8">
            <v-checkbox
              label="Componente Diversificado"
              v-model="form.campo_diversificado"
            ></v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              block
              >Enviar Dados</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("Disciplinas", ["disciplinasList", "carregandoDisciplinas"]),
    ...mapGetters("Disciplinas", ["disciplinas"]),
    form() {
      if (!this.disciplinas || !this.editing) return {};
      const { disciplina_id } = this.$route.params;
      const disciplina = this.disciplinas.get(parseInt(disciplina_id, 10));
      return {
        ...disciplina,
      };
    },
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      submittingForm: false,
    };
  },
  methods: {
    ...mapActions("Disciplinas", ["loadDisciplinas"]),
    async submitForm() {
      this.submittingForm = true;
      console.log(this.form);
      try {
        if (this.editing) {
          await this.$services.disciplinasService.atualizarDisciplina(this.form);
        } else {
          await this.$services.disciplinasService.criarDisciplina(this.form);
        }
        this.$toast.success("Disciplina Atualizada com sucesso");
        this.$router.push({ name: "disciplinas" });
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
    async loadData() {
      try {
        if (this.disciplinas.length === 0) {
          this.$loaderService.open("Carregando dados");
        }
        await Promise.all([this.loadDisciplinas()]);
        this.$loaderService.close();
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style></style>
