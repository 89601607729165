<template>
  <main-template hide-franquia>
    <v-row class="mb-3">
      <v-col cols="12" class="pt-10">
        <h1>Escolas:</h1>
      </v-col>
      <v-col cols="12" v-if="franquiasList.length === 0">
        <v-alert color="warning" icon="fa fa-frown-o"
          >Infelizmente ainda não lhe adicionaram em nenhuma Escola, entre em contato com o setor de
          secretaria/coordenação da sua escola para que criem seus vínculos no diário e suas escolas
          serão listadas aqui.
        </v-alert>
      </v-col>
      <v-col
        v-else
        cols="12"
        md="4"
        v-for="franquia of franquiasList"
        :key="franquia.id"
        align-self="stretch"
      >
        <v-card
          color="indigo darken-4 white--text"
          @click="selecionarFranquiaERedirecionar(franquia)"
          style="height: 100%"
          class="d-flex flex-column justify-space-between"
        >
          <v-card-title>
            <v-icon large left color="white"> mdi-account-multiple </v-icon>
            <span class="title font-weight-light">{{ franquia.descricao }}</span>
          </v-card-title>
          <v-card-text
            v-if="user.roleName === 'professor'"
            class="subtitle font-weight-bold white--text"
          >
            Diários: {{ franquia.qtdGestoesDeAulas }}
          </v-card-text>
          <v-card-text
            v-else-if="user.roleName === 'prof-auxiliar'"
            class="subtitle font-weight-bold white--text"
          >
            Diários: {{ franquia.qtdGestoesDeAulasAuxiliar }}
          </v-card-text>
          <!-- roleName -->
        </v-card>
      </v-col>
    </v-row>

    <v-divider> </v-divider>

    <v-row class="mt-3 mb-3" v-if="show">
      <v-col cols="6" md="6">
        <v-chip color="indigo" label text-color="white"
          >Quantidade de notas acima e abaixo da média
        </v-chip>

        <v-card class="mt-2">
          <e-dashboard-turma :datasets="datasetsNotas" :labels="labelsNotas" />
        </v-card>
      </v-col>

      <v-col cols="6" md="6">
        <v-chip color="indigo" label text-color="white"> Média de notas </v-chip>
        <v-card class="mt-2">
          <e-dashboard-turma :datasets="datasetsMedias" :labels="labelsMedias" />
        </v-card>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row class="mt-3 mb-3" v-if="show">
      <v-col>
        <v-chip color="indigo" label text-color="white">
          Quantidade de presenças e faltas por mês
        </v-chip>
        <v-card class="mt-2">
          <e-dashboard-turma :datasets="datasetsAcompanhamentos" :labels="labelsAcompanhamentos" />
        </v-card>
      </v-col>
    </v-row>

    <v-divider> </v-divider>

    <v-row class="mt-3 mb-1" v-if="show">
      <v-col cols="12">
        <v-chip color="indigo" label text-color="white"> Melhores alunos</v-chip>
        <v-card v-for="(item, i) in melhoresAlunos" :key="i" light class="mb-3 mt-1">
          <div class="d-flex flex-no-wrap justify-content">
            <div style="border-right: 1px solid #ccc" class="py-5">
              <div class="d-flex flex-no-wrap justify-content">
                <v-img width="100" :src="coroas[i]"></v-img>
              </div>
              <div style="text-align: center">
                <h3>#{{ ++i }}</h3>
              </div>
            </div>

            <div class="py-5 ml-2">
              <div>
                <h3 class="indigo--text mb-3">
                  {{ item.aluno }}
                </h3>
              </div>
              <v-divider></v-divider>
              <div class="mt-3">
                <span class="">Média: {{ item.media }}</span> <br />
                <span class="">Notas: {{ item.soma_notas }}</span> <br />
                <span class="">Faltas: {{ item.qtdFaltas }}</span>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row class="mt-2" v-if="show">
      <v-col>
        <v-chip color="indigo" label text-color="white"> Alunos </v-chip>
        <v-card class="mt-2">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Procurar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="alunos" :search="search"></v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      show: true,
      turmas: [],
      query: "",
      openSelecionarFranquiaModal: false,
      aulasAguardandoConfirmacaoCount: null,
      carregandoTurmas: false,
      franquiasList: [],
      carregandoFranquias: false,
      labelsNotas: [],
      datasetsNotas: [],
      datasetsMedias: [],
      labelsMedias: [],
      datasetsAcompanhamentos: [],
      labelsAcompanhamentos: [],
      alunos: [],
      melhoresAlunos: [],
      coroas: [
        "https://img.freepik.com/vetores-gratis/composicao-da-coroa-real-com-imagem-plana-da-coroa-cercada-por-coroas-de-louro-douradas_1284-64252.jpg?w=740&t=st=1661362754~exp=1661363354~hmac=29523ea348e1807edaa9ca4d5abf172c4753f0e7d8a6a968a3c2ff064eaa087a",
        "https://static.vecteezy.com/system/resources/previews/000/554/920/large_2x/royal-crown-vector-icon.jpg",
        "https://static.vecteezy.com/system/resources/previews/000/554/920/large_2x/royal-crown-vector-icon.jpg",
        "https://static.vecteezy.com/system/resources/previews/000/554/920/large_2x/royal-crown-vector-icon.jpg",
      ],
      search: "",
      headers: [
        { text: "Nome", value: "aluno" },
        { text: "Média", value: "media" },
        { text: "Notas", value: "soma_notas" },
        { text: "Faltas", value: "qtdFaltas" },
        { text: "Presenças", value: "qtdPresencas" },
      ],
    };
  },
  async mounted() {
    await this.loadData();
  },
  computed: {
    ...mapGetters("Auth", ["senhaFraca", "user"]),
  },
  methods: {
    async selecionarFranquiaERedirecionar(franquia) {
      try {
        this.$loaderService.open("Acessando diários");
        await this.$services.authService.mudarDeFranquia(franquia);
        if (this.user.roleName === "professor") {
          this.$router.push({ name: "gestoesDeAulas" });
        } else {
          this.$router.push({ name: "gestoesDeAulasAuxiliares" });
        }
        this.$loaderService.close();
      } catch (error) {
        console.log(error);
      }
    },

    async loadData() {
      this.$loaderService.open("Carregando dados");
      try {
        this.franquiasList = await this.$services.franquiasService.syncAll();
        this.getTurmas();

        await this.getNotas();
        await this.getMedias();
        await this.getAlunos();
        await this.getAcompanhamento();

        /* eslint-disable */
        this.franquiasList.map((f) => {
          f.gestoes_de_aulas.map((g) => {
            if (g?.turma?.isInfantil) {
              return (this.show = false);
            }
          });
        });
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    getTurmas() {
      this.franquiasList.map((franquia) =>
        franquia.gestoes_de_aulas.map((f) => this.turmas.push(f?.turma?.id))
      );
    },

    async getNotas() {
      try {
        const data = await this.$services.turmasService.pegarNotas({
          turmas: this.turmas,
        });
        const values = data.data;
        const dados = [];

        Object.keys(values).forEach((key) => {
          dados.push({
            label: values[key].label,
            backgroundColor: values[key].backgroundColor,
            data: values[key].data.map((n) => parseInt(n, 10)).filter((n) => n),
          });
        });

        this.datasetsNotas = dados;
        this.labelsNotas = values.notasAzuis.texts;
      } catch (error) {
        console.log(error);
      }
    },

    async getMedias() {
      try {
        const data = await this.$services.turmasService.pegarMedias({
          turmas: this.turmas,
        });
        const values = data.data;
        const dados = [];

        Object.keys(values).forEach((key) => {
          dados.push({
            label: values[key].label,
            backgroundColor: values[key].backgroundColor,
            data: values[key].data.map((n) => parseInt(n, 10)).filter((n) => n),
          });
        });

        this.labelsMedias = values.notas.texts;
        this.datasetsMedias = dados;
      } catch (error) {
        console.log(error);
      }
    },

    async getAlunos() {
      try {
        const data = await this.$services.turmasService.pegarMelhoresAlunos({
          turmas: this.turmas,
        });
        this.alunos = data.data;
        this.melhoresAlunos = data.data
          .map((value, index) => (index < 3 ? value : null))
          .filter((a) => a);

        this.$loaderService.close();
      } catch (error) {
        console.log(error);
      }
    },

    async getAcompanhamento() {
      try {
        const data = await this.$services.turmasService.pegarAcompanhamentos({
          turmas: this.turmas,
        });
        const values = data.data;
        const dados = [];

        Object.keys(values).forEach((key) => {
          dados.push({
            label: values[key].label,
            backgroundColor: values[key].backgroundColor,
            data: values[key].data.map((n) => parseInt(n, 10)),
          });
        });

        this.datasetsAcompanhamentos = dados;
        this.labelsAcompanhamentos = values.faltas.mesesAll.map((m) => m?.descricao);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
