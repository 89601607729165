import Model from "./Model";

export default class FichaDiarioAtendimento extends Model {
  constructor(data) {
    super();

    if (Array.isArray(data.ficha)) {
      data.ficha.map((item) => {
        return (item.data = item.data ? item.data.split("-").reverse().join("/") : "");
      });
    }

    Object.assign(this, data);
  }
}
