import sistemaBNCCService from "./SistemaBNCCService";
import alunosService from "./AlunosService";
import anosService from "./AnosService";
import authService from "./AuthService";
import caixasService from "./CaixasService";
import franquiasService from "./FranquiasService";
import matriculasService from "./MatriculasService";
import formasDePagamentoService from "./FormasDePagamentoService";
import parcelasService from "./ParcelasService";
import turmasService from "./TurmasService";
import turnosService from "./TurnosService";
import seriesService from "./SeriesService";
import cursosService from "./CursosService";
import cepService from "./CepService";
import disciplinasService from "./DisciplinasService";
import professoresService from "./ProfessoresService";
import usuariosService from "./UsuariosService";
import inepService from "./InepService";
import gestoesDeAulasService from "./GestoesDeAulasService";
import gestoesDeAulasAuxiliaresService from "./GestoesDeAulasAuxiliaresService";
import aulasService from "./AulasService";
import notasService from "./NotasService";
import relatoriosService from "./RelatoriosService";
import tiposDeEnsinosService from "./TiposDeEnsinosService";
import educacaoInclusivaService from "./EducacaoInclusivaService";
import planosBimestraisService from "./planosBimestraisService";
import planosBimestraisInfantilService from "./planosBimestraisInfantilService";
import rolesService from "./RolesService";
import responsavelAlunoService from "./ResponsavelAlunoService";
import avisosService from "./AvisosService";
import imageService from "./ImageService";
import objetivosAprendizagem from "./ObjetivosAprendizagem";
import horariosService from "./HorariosService";
import agendasService from "./AgendaService";
import notifiqService from "./NotifiqService";
import planosService from "./PlanosService";
import circuitoNotasService from "./CircuitoNotasService";
import etapasService from "./EtapasService";
import subEtapasService from "./SubEtapasService";
import autorizacoesService from "./AutorizacoesService";
import regrasService from "./RegrasService";
import webInfo from "./WebInfo";
import informativosService from "./InformativosService";

const $services = {
  alunosService,
  anosService,
  authService,
  caixasService,
  franquiasService,
  matriculasService,
  formasDePagamentoService,
  parcelasService,
  turmasService,
  turnosService,
  seriesService,
  cursosService,
  cepService,
  disciplinasService,
  professoresService,
  usuariosService,
  inepService,
  gestoesDeAulasService,
  aulasService,
  notasService,
  relatoriosService,
  sistemaBNCCService,
  tiposDeEnsinosService,
  educacaoInclusivaService,
  planosBimestraisService,
  rolesService,
  responsavelAlunoService,
  avisosService,
  imageService,
  planosBimestraisInfantilService,
  objetivosAprendizagem,
  horariosService,
  planosService,
  gestoesDeAulasAuxiliaresService,
  agendasService,
  notifiqService,
  circuitoNotasService,
  etapasService,
  subEtapasService,
  autorizacoesService,
  regrasService,
  webInfo,
  informativosService,
};

export default $services;
